/**
 * Created by Ivan on 03.01.2025
 */

var BonusLevelChest = cc.Node.extend({
    ctor: function (timeLeft, isVertical) {
        this._super();

        this.isVertical = isVertical;

        this.setAnchorPoint(0.5, 0.5);

        this.addColumn();
        this.addChest();
        this.addTimer(timeLeft);

        cleverapps.UI.wrap(this);
    },

    addColumn: function () {
        var spinePath = bundles.game.jsons[this.isVertical ? "bonus_cloud_vertical_json" : "bonus_cloud_horizontal_json"];
        var column = this.column = new cleverapps.Spine(spinePath);

        this.addChild(column);
        column.setPositionRound(cleverapps.styles.BonusLevelChest.column);
        column.setAnimation(0, "animation", true);
    },

    addChest: function () {
        var styles = cleverapps.styles.BonusLevelChest.chest;

        var chestPath = bundles.game.jsons[this.isVertical ? "bonus_chest_vertical_json" : "bonus_chest_horizontal_json"];
        var chest = this.chest = new cleverapps.Spine(chestPath);
        chest.setPositionRound(this.isVertical ? styles.vertical : styles);
        this.addChild(chest);
        chest.setAnimation(0, "animation", true);
    },

    addTimer: function (timeLeft) {
        var styles = cleverapps.styles.BonusLevelChest.timer;

        var timer = this.timer = new Timer(timeLeft, function () {
            timer.runAction(AnimationsLibrary.disappear(timer));
        }, {
            width: styles.width,
            height: styles.height,
            timerPosition: styles.countdown,
            timerBg: bundles.game.frames.bonus_timer_bg_png,
            timerIcon: bundles.game.frames.bonus_timer_icon_png,
            showHours: false
        });
        timer.setPositionRound(this.isVertical ? styles.vertical : styles);
        this.addChild(timer);
    },

    open: function () {
        this.chest.setAnimation(0, "open", false);
    },

    show: function (positions) {
        var scene = cleverapps.scenes.getRunningScene();

        var position = cleverapps.UI.findPosition(this, {
            preferredPositions: cleverapps.toArray(positions),
            avoidNodes: [scene.upMenuContainer.menuBar]
        });

        this.setVisible(true);
        this.setPositionRound(position);
    },

    hide: function () {
        this.setVisible(false);
    },

    remove: function (callback) {
        this.chest.runAction(new cc.Spawn(
            new cc.FadeOut(0.3),
            new cc.Sequence(
                new cc.ScaleTo(0.3, 0.5).easing(cc.easeBackIn()),
                new cc.CallFunc(callback),
                new cc.RemoveSelf()
            )
        ));

        this.timer.runAction(new cc.Sequence(
            new cc.Spawn(
                new cc.FadeOut(0.3),
                new cc.ScaleTo(0.3, 0.5).easing(cc.easeBackIn())
            ),
            new cc.RemoveSelf()
        ));
    }
});

cleverapps.styles.BonusLevelChest = {
    column: {
        x: { align: "center", dx: 0 },
        y: { align: "center", dy: 0 }
    },

    timer: {
        width: 180,
        height: 60,
        x: { align: "center", dx: 0 },
        y: { align: "top", dy: 140 },

        countdown: {
            x: { align: "center", dx: 8 },
            y: { align: "center", dy: 3 }
        },

        vertical: {
            x: { align: "center", dx: 0 },
            y: { align: "top", dy: 10 }
        }
    },

    chest: {
        x: { align: "center", dx: 10 },
        y: { align: "top", dy: 370 },

        vertical: {
            x: { align: "center", dx: 0 },
            y: { align: "top", dy: 150 }
        }
    }
};