/**
 * Created by slava on 24.03.17.
 */

var Keypad = function (game, save) {
    KeypadInterface.call(this);

    this.game = game;
    this.level = game.level;
    this.saved = save.keypad;
    this.openLetterBooster = game.openLetterBooster;

    var letters = game.level.content.base.toLowerCase();
    if (this.saved && this.saved.letters && !cleverapps.config.wysiwygMode) {
        letters = this.saved.letters;
    }

    this.letters = letters.split("").map(function (char, index) {
        return new KeypadLetter(char, index, this);
    }.bind(this));

    this.indexes = [];

    this.setControlType(Keypad.CONTROL_TYPES.UNDEFINED);
};

Keypad.prototype = Object.create(KeypadInterface.prototype);
Keypad.prototype.constructor = Keypad;

Keypad.prototype.getInfo = function () {
    return {
        letters: this.letters.map(function (letter) {
            return letter.symbol;
        }).join(""),
        indexes: this.indexes.map(function (letter) {
            return letter.index;
        })
    };
};

Keypad.prototype.load = function () {
    if (!this.saved || this.saved.indexes.length === 0) {
        return;
    }

    if (!cleverapps.config.wysiwygMode) {
        this.grabFocus();
    }
    this.setControlType(Keypad.CONTROL_TYPES.SNAPSHOT_CLICK, true);
    if (this.indexes.length === 0) {
        this.saved.indexes.forEach(function (index) {
            if (this.letters[index]) {
                this.push(this.letters[index], true);
            }
        }.bind(this));
    }
};

Keypad.prototype.hide = function (f) {
    this.trigger("hide", f);
};

Keypad.prototype.hideShuffleButton = function () {
    this.trigger("hideShuffleButton");
};

Keypad.prototype.showShuffleButton = function () {
    this.trigger("showShuffleButton");
};

Keypad.prototype.touchBegan = function (letter) {
    if (this.game.counter.isActive()) {
        return false;
    }

    cleverapps.centerHint.finishCenterHint();

    if (!letter) {
        return false;
    }

    if (!this.grabFocus()) {
        return false;
    }

    this.letterTouched(letter);

    return true;
};

Keypad.prototype.touchEnded = function () {
    if (this.openLetterBooster && this.openLetterBooster.isActive()) {
        return;
    }

    if (this.controlType === Keypad.CONTROL_TYPES.UNDEFINED) {
        this.setControlType(Keypad.CONTROL_TYPES.CLICK);
    }

    if (this.indexes.length === 0) {
        this.cancel();
    }

    if (this.controlType === Keypad.CONTROL_TYPES.MOVE) {
        this.submit();
    }

    this.letterTouchBlocked = false;
};

Keypad.prototype.letterTouched = function (letter) {
    if (this.openLetterBooster) {
        if (this.openLetterBooster.isActive()) {
            if (Game.currentGame.board.selectLettersPositions(letter.symbol).length === 0) {
                return;
            }

            this.openLetterBooster.open(letter);

            this.openLetterChoosed();
            return;
        }

        if (this.openLetterBooster.isAnimating()) {
            return;
        }
    }

    if (!this.letterTouchBlocked) {
        this.move(letter);

        this.trigger("touchBegan");
    }

    if (this.controlType === Keypad.CONTROL_TYPES.CLICK) {
        this.letterTouchBlocked = true;
    }
};

Keypad.prototype.findWordIndexes = function (word) {
    var busy = {};
    var indexes = [];

    for (var i = 0; i < word.length; i++) {
        for (var j = 0; j < this.letters.length; j++) {
            if (!busy[j] && this.letters[j].symbol === word[i]) {
                busy[j] = true;
                indexes.push(this.letters[j]);
                break;
            }
        }
    }

    return indexes;
};

Keypad.prototype.stopped = function () {
    this.freeFocus();

    var activeScenario = cleverapps.tutorial.getActive();
    if (activeScenario) {
        if (this.combineWord(this.indexes) !== activeScenario.getCurrentStep().word) {
            this.reset();
            return;
        }
    }

    this.trigger("attempt", this.indexes);
};

Keypad.prototype.combineWord = function (letters) {
    if (typeof letters === "string") {
        return letters;
    }

    return letters.map(function (letter) {
        return letter.symbol;
    }).join("");
};

Keypad.prototype.grabFocus = function () {
    if (["KeypadControl", "СhooseBoosterLetter"].includes(cleverapps.focusManager.focus) || cleverapps.tutorial.isActive()) {
        return true;
    }

    var controls = ["MenuBarGoldItem", "MenuBarBonusRoundTimerItem", "cookieJar", "ControlButtonsView", "tournamentPlayersView", "flyingAd"];
    if (cleverapps.resolution.mode !== cleverapps.WideMode.VERTICAL) {
        controls.push("hint_3", "rewardElementClover");
    }

    cleverapps.focusManager.display({
        focus: "KeypadControl",
        control: controls,
        stack: true,
        action: function (f) {
            this.trigger("grabFocus");

            cleverapps.boosters.listBoosters().forEach(function (booster) {
                booster.onToggleArrowListener();
                booster.onToggleTintListener();
            });

            this.onceControlFinished = f;
        }.bind(this)
    });

    return cleverapps.focusManager.focus === "KeypadControl";
};

Keypad.prototype.freeFocus = function () {
    if (this.game.board.isComplete() && this.onceControlFinished) {
        this.onceControlFinished();
        delete this.onceControlFinished;
    }
};

Keypad.prototype.showUp = function (f, silent) {
    this.trigger("showUp", f, silent);

    this.load();
};

Keypad.prototype.shuffle = function () {
    this.letters = cleverapps.Random.shuffle(this.letters);

    this.letters.forEach(function (letter, index) {
        letter.shuffle(index);
    });

    this.game.counter.setTimeout(function () {
        this.setControlType(Keypad.CONTROL_TYPES.UNDEFINED);
    }.bind(this), this.getShuffleDuration() * 1000);

    this.setControlType();

    this.trigger("shuffle");
};

Keypad.prototype.getShuffleDuration = function () {
    return 1.5;
};

Keypad.prototype.listIndexes = function () {
    return this.indexes;
};

Keypad.prototype.amountKeys = function () {
    return this.letters.length;
};

Keypad.prototype.status = function (status) {
    this.indexes.forEach(function (letter) {
        letter.status(status);
    });

    this.trigger("status", status);

    this.indexes = [];
};

Keypad.prototype.reset = function () {
    this.freeFocus();
    this.trigger("reset");

    while (this.indexes.length > 0) {
        this.pop(true);
    }
};

Keypad.prototype.move = function (letter) {
    if (!letter.selected) {
        this.push(letter);
        return;
    }

    var isOnLast = this.indexes.length >= 1 && this.indexes[this.indexes.length - 1] === letter;
    var isOnPrevLast = this.indexes.length >= 2 && this.indexes[this.indexes.length - 2] === letter;
    if (isOnPrevLast || isOnLast && this.controlType === Keypad.CONTROL_TYPES.CLICK) {
        this.pop();
    }
};

Keypad.prototype.push = function (letter, silent) {
    this.indexes.push(letter);
    letter.select();

    this.trigger("push", letter, silent);
};

Keypad.prototype.pop = function (silent) {
    this.trigger("pop", silent);

    this.indexes.pop().unselect();
};

Keypad.prototype.setControlType = function (controlType, silent) {
    if (controlType === Keypad.CONTROL_TYPES.CLICK) {
        cleverapps.focusManager.hideAllControls(silent);
        cleverapps.focusManager.showControlsWhileFocused(["MenuBarGoldItem", "flyingAd"]);
    }
    this.controlType = controlType;
    this.trigger("controlTypeChanged", controlType);
};

Keypad.prototype.submit = function () {
    this.stopped();
    this.setControlType(Keypad.CONTROL_TYPES.UNDEFINED);
};

Keypad.prototype.cancel = function () {
    this.reset();
    this.setControlType(Keypad.CONTROL_TYPES.UNDEFINED);
};

Keypad.prototype.swipe = function () {
    if (this.controlType === Keypad.CONTROL_TYPES.UNDEFINED) {
        this.setControlType(Keypad.CONTROL_TYPES.MOVE);
    }
};

Keypad.prototype.chooseBoosterLetter = function () {
    cleverapps.focusManager.display({
        focus: "СhooseBoosterLetter",
        stack: true,
        action: function (f) {
            this.trigger("chooseLetter");
            this.openLetterChoosed = function () {
                this.trigger("stopChooseLetter");
                f();
            }.bind(this);
        }.bind(this)
    });
};

Keypad.CONTROL_TYPES = {
    MOVE: 0,
    CLICK: 1,
    UNDEFINED: 2,
    SNAPSHOT_CLICK: 3
};