/**
 * Created by slava on 23.03.17.
 */

var WordGameScene = GameSceneBase.extend({
    onSceneLoaded: function () {
        this._super();

        var level = this.level;
        var game = this.game;

        if (cleverapps.config.subtype === "stacks") {
            LetterBlockView.bgImage = this.styling.bgImage;
            LetterBlockView.bgImageHighlighted = this.styling.bgImageHighlighted;
            LetterBlockView.bgImageHint = this.styling.bgImageHint;
            LetterBlockView.hintColor = this.styling.hintColor;
            cleverapps.BoardView.foundWordBg = this.styling.foundWordBg;
        }

        var keypadView = this.keypadView = new KeypadView(game.keypad);
        this.addChild(keypadView);

        var boardView = this.boardView = new cleverapps.BoardView(game.board);
        this.addChild(boardView);

        if (this.playersView) {
            this.playersView.replaceParent(this.boardView.boardBg);
            this.playersView.setPositionRound(cleverapps.styles.WordGameScene.playersView);
        }

        if (!cleverapps.gameModes.noCurrent) {
            var currentView = this.currentView = new cleverapps.CurrentView(game.current);
            this.addChild(currentView);
        }

        if (game.hintBooster.isAvailable()) {
            this.createHintButton();
        }

        if (game.openLetterBooster && game.openLetterBooster.isAvailable()) {
            this.createOpenLetterButton();
        }

        var shuffleBooster = cleverapps.boosters.getBoosterById(cleverapps.Boosters.TYPE_SHUFFLE);
        if (shuffleBooster && shuffleBooster.isAvailable()) {
            var shuffleButton = new ShuffleButtonBooster(shuffleBooster);
            this.addChild(shuffleButton);

            this.shuffleButton = new HidingNode(shuffleButton);
            cleverapps.focusManager.registerControl(shuffleButton.getControlId(), this.shuffleButton);
        }

        var rewardForExtra = this.level.isRegular() && this.level.isNew() || !this.level.isRegular();
        levels.cookieJar.setShouldRewardInCurrentLevel(rewardForExtra);

        if (rewardForExtra && !cleverapps.gameModes.noCookieJar) {
            new CookieJarView(game.extra, this);
        }

        if (level.content.hint) {
            HintView.themeBg = this.styling.themeBg;
            var hintView = this.hintView = new HintView(level.content.hint, game);
            this.addChild(hintView);
        }

        if (this.game.bonusTimer) {
            var bonusLevelView = this.bonusLevelView = new BonusLevelView(this.game.board, this.game.bonusTimer.getTimeLeft());
            this.addChild(bonusLevelView, -1);
        }

        this.setupChildren();
    },

    playIntro: function (f) {
        this._super(f);

        if (!cleverapps.tutorial.isActive()) {
            var level = cleverapps.user.getVirtualProgress() + 1;
            cleverapps.centerHint.createTextHint("StatisticsHint.Text", {
                percent: this.game.calcCompletePercent(level),
                level: level
            }, Dialogue.POSITIONS.BOTTOM);
        }
    },

    scaleGameField: function () {
        var styles = cleverapps.styles.BoardView;

        var currentPosition = this.currentView && this.currentView.getPosition();
        var centerPoint = currentPosition && this.convertToWorldSpace(cc.p(currentPosition.x, currentPosition.y + 100 * resolutionScale));

        var availableRect = cleverapps.UI.calcBoundaries(this.getOverlappingNodes(), {
            centerPoint: centerPoint
        });

        availableRect = cc.rectSubPadding(availableRect, cc.padding(styles.padding));
        availableRect = cc.convertRectToNodeSpace(this, availableRect);

        this.boardView.updateGrid(availableRect);
        this.boardView.updateBackground();
    },

    getOverlappingNodes: function () {
        var nodes = [this.keypadView, this.upMenuContainer, this.currentView, this.hintView];

        if (this.bonusLevelView) {
            nodes = nodes.concat(this.bonusLevelView.getOverlappingNodes());
        }

        return nodes.filter(Boolean);
    },

    createHintButton: function () {
        var hintButton = new HintButtonBooster(this.game.hintBooster);
        this.addChild(hintButton);

        this.hintButton = new HidingNode(hintButton);
        cleverapps.focusManager.registerControl(hintButton.getControlId(), this.hintButton);
    },

    createOpenLetterButton: function () {
        var openLetterButton = new OpenLetterButtonBooster(this.game.openLetterBooster, this);
        this.addChild(openLetterButton);

        this.openLetterButton = new HidingNode(openLetterButton);
        cleverapps.focusManager.registerControl(openLetterButton.getControlId(), this.openLetterButton);
    }
});

GameScene = WordGameScene;

cleverapps.styles.WordGameScene = {
    minimalPosition: {
        x: { align: "center" },
        y: { align: "center", dy: -45 }
    },

    playersView: {
        x: { align: "center" },
        y: { align: "top", dy: 5 }
    }
};

if (cleverapps.config.subtype === "stacks") {
    cleverapps.overrideStyles(cleverapps.styles.GameScene.STYLING, {
        bonus: {
            bgImage: bundles.letters.frames.letter_keypad_green_bg_png,
            bgImageHighlighted: bundles.letters.frames.letter_keypad_yellow_bg_png,
            foundWordBg: bundles.game.frames.found_word_bg_bonus_png,
            hintColor: new cc.Color(144, 0, 236, 255),
            themeBg: bundles.game.frames.theme_bg_regular_green
        },

        hard: {
            bgImage: bundles.letters.frames.letter_keypad_orange_bg_png,
            bgImageHighlighted: bundles.letters.frames.letter_keypad_red_bg_png,
            foundWordBg: bundles.game.frames.found_word_bg_hard_png,
            hintColor: new cc.Color(144, 0, 236, 255),
            themeBg: bundles.game.frames.theme_bg_hard
        },

        tricky: {
            bundle: "game_background_vertical",
            skin: "gameBackground",
            music: bundles.game.urls.background_music_tricky,

            bgImage: bundles.letters.frames.letter_keypad_light_blue_bg_png,
            bgImageHighlighted: bundles.letters.frames.letter_keypad_pink_bg_png,
            foundWordBg: bundles.game.frames.found_word_bg_tricky_png,
            hintColor: new cc.Color(244, 234, 138, 255),
            themeBg: bundles.game.frames.theme_bg_tricky
        }
    });

    cleverapps.overrideStyles(cleverapps.styles.GameScene.STYLING.regular[0], {
        bgImage: bundles.letters.frames.letter_keypad_green_bg_png,
        bgImageHighlighted: bundles.letters.frames.letter_keypad_yellow_bg_png,
        foundWordBg: bundles.game.frames.found_word_bg_bonus_png,
        hintColor: new cc.Color(144, 0, 236, 255),
        themeBgColor: new cc.Color(112, 192, 57, 220)
    });

    cleverapps.overrideStyles(cleverapps.styles.GameScene.STYLING.regular[1], {
        bgImage: bundles.letters.frames.letter_keypad_blue_bg_png,
        bgImageHighlighted: bundles.letters.frames.letter_keypad_purple_bg_png,
        foundWordBg: bundles.game.frames.found_word_bg_png,
        hintColor: new cc.Color(250, 28, 115, 255),
        themeBgColor: new cc.Color(36, 151, 224, 220)
    });

    cleverapps.overrideStyles(cleverapps.styles.GameScene.STYLING.regular[2], {
        bgImage: bundles.letters.frames.letter_keypad_blue_bg_png,
        bgImageHighlighted: bundles.letters.frames.letter_keypad_purple_bg_png,
        foundWordBg: bundles.game.frames.found_word_bg_png,
        hintColor: new cc.Color(250, 28, 115, 255),
        themeBgColor: new cc.Color(36, 151, 224, 220)
    });
}
