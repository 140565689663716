/**
 * Created by olga on 22.01.2025
 */

var TrickyStacksTutorial = function () {
    return new TutorialScenario("tricky", {
        listSteps: TrickyStacksTutorial.listSteps,
        isAvailable: TrickyStacksTutorial.isAvailable
    });
};

TrickyStacksTutorial.listSteps = function () {
    var options = {};
    options.word = function () {
        return Game.currentGame.keypad.getExistedWords()[0];
    };
    
    options.force = Forces.TRICKY_LEVEL;
    options.highlightBoard = true;

    return [BoardTutorialHelper.getDiscoverWordStep(options)];
};

TrickyStacksTutorial.isAvailable = function () {
    var game = Game.currentGame;
    return game && game.keypad.tricky && !cleverapps.forces.isShown(Forces.TRICKY_LEVEL.id);
};