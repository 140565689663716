/**
 * Created by mac on 3/23/17.
 */

var WordGame = function (level, options) {
    options = options || {};

    GameBase.call(this, level, options);

    this.counter.registerStage(100, this.discoverOpened.bind(this));
    this.counter.registerStage(110, this.saveStable.bind(this));
    this.counter.registerStage(200, this.showExclamation.bind(this));
    this.counter.registerStage(400, this.forceHintButton.bind(this));
    this.counter.registerStage(800, this.checkComplete.bind(this));
    this.counter.registerStage(801, this.checkOneWordLeft.bind(this));
    this.counter.registerStage(900, this.showInterstitial.bind(this));
    this.counter.registerStage(2000, this.resetStatus.bind(this));

    var save = this.savedGame;

    if (save.clover === undefined) {
        save.clover = 0;
        this.needSave = true;
    }
    if (save.timeStart === undefined) {
        this.needSave = true;
    }

    save.words = (save.words || []).map(function (word) {
        return word.toLowerCase();
    });

    save.extra = (save.extra || []).map(function (word) {
        return word.toLowerCase();
    });

    this.emptySave = save.empty;

    if (this.secondaryMission) {
        this.rewards.clover = save.clover;
    }

    this.collectedCoins = 0;

    this.hintBooster = cleverapps.boosters.getBoosterById(cleverapps.Boosters.TYPE_HINT);
    this.openLetterBooster = cleverapps.boosters.getBoosterById(cleverapps.Boosters.TYPE_OPEN_LETTER);
    this.shaffleBooster = cleverapps.boosters.getBoosterById(cleverapps.Boosters.TYPE_SHUFFLE);

    // todo move register BoosterTutorial to initializeboardgame
    // todo  BoosterTutorial should not accept argument with a booster, but should search through all available boosters and run the tutorial with the required one
    // todo  BoosterTutorial should create Force itself but not call booster.showForce();

    if (this.openLetterBooster) {
        cleverapps.tutorial.register(BoosterTutorial(this.openLetterBooster));
    }

    if (this.shaffleBooster) {
        cleverapps.tutorial.register(BoosterTutorial(this.shaffleBooster));
    }

    this.keypad = new Keypad(this, save);
    this.keypad.on("attempt", this.onAttempt.bind(this));

    this.board = new cleverapps.Board(this, save.words, save.hints);

    this.extra = new cleverapps.Extra(this.levelContent.extra, save.extra);
    this.extra.onChangedListener = this.storeSave.bind(this);

    this.current = new cleverapps.Current(this, this.keypad);

    var tournament = level.isRegular() && level.isNew();
    if (!this.primaryMission || !this.primaryMission.tournament || this.timer.getStartTime() < this.primaryMission.started || cleverapps.isKnockoutGame()) {
        tournament = false;
    }

    this.streakCounter = 0;

    var competitionOptions;
    if (cleverapps.isRumble()) {
        competitionOptions = Competition.optionsForKnockoutGame(this);
    } else if (tournament) {
        competitionOptions = Competition.optionsForTournament(this);
    }

    if (competitionOptions) {
        this.competition = new Competition(competitionOptions);
        this.on("beforeStartActions", this.competition.start.bind(this.competition), this);
        this.competition.onLoseListener = function () {
            if (!this.board.isComplete()) {
                this.lose();
            }
        }.bind(this);

        this.tournament = tournament;
    }

    if (["scramble"].indexOf(cleverapps.config.name) !== -1) {
        this.currentHighlighter = new CurrentHighlighter(this, save);
    }

    if (this.needSave) {
        this.storeSave();
    }

    this.hintNotification = new HintNotification(this);
    
    if (cleverapps.config.features.includes("butterfly") && this.getMissionType() === Mission.TYPE_BUTTERFLY && (!this.level.isBonus() || cleverapps.config.subtype === "stacks")) {
        new Butterfly();
    }

    if (!this.emptySave) {
        InterstitialAction.showOnLowMonetization();
    }

    if (this.level.isBonus() && cleverapps.config.subtype !== "stacks") {
        this.bonusTimer = new cleverapps.CountDown(cleverapps.parseInterval(WordGame.BONUS_LEVEL_DURATION));
        this.bonusTimer.onFinish = function () {
            this.win();
        }.bind(this);
    }
};

var Game = WordGame;

WordGame.prototype = Object.create(GameBase.prototype);
WordGame.constructor = WordGame;

WordGame.prototype.saveStable = function () {
    if (this.outcome === GameBase.OUTCOME_UNKNOWN) {
        this.storeSave();
    }
};

WordGame.prototype.showExclamation = function () {
    if (this.needShowExclamation && !this.board.isComplete()) {
        cleverapps.exclamation.show(cleverapps.Random.mathChoose(Messages.get("congratulations")));
        delete this.needShowExclamation;
    }
};

WordGame.prototype.forceHintButton = function () {
    var foundWords = this.board.countDiscoveredWords();
    if (foundWords < this.board.words.length) {
        this.trigger("forceHint");
    }
};

WordGame.prototype.onAttempt = function (letters) {
    this.begin(true);

    var word = this.keypad.combineWord(letters);
    var status = this.analyze(word);

    this.keypad.status(status, letters);
    this.current.status(status, word);

    if (status === WordGame.STATUSES.BOARD) {
        this.streakCounter++;
        this.discover(word, letters);
        cleverapps.userStatus.reportUserAction();
    } else {
        this.streakCounter = 0;
    }

    if (status === WordGame.STATUSES.EXTRA) {
        this.extra.discover(word);
        cleverapps.userStatus.reportUserAction();
    }

    if (status === WordGame.STATUSES.EXTRA_FOUND) {
        this.extra.wordFound(word);
    }

    if (status === WordGame.STATUSES.BOARD_FOUND) {
        this.board.alreadyDiscovered(word);
    }

    if (status === WordGame.STATUSES.WRONG) {
        this.trigger("WrongWord_Booster");
    }

    this.status = status;
};

WordGame.prototype.discover = function (word, letters) {
    cleverapps.dailyTasks.processDiscoverWord(word);

    this.board.discover(word, letters);

    if (this.streakCounter >= 2) {
        this.needShowExclamation = true;
        this.streakCounter = 0;
    }

    this.keypad.markDiscovered(word, letters);

    if (cleverapps.config.subtype === "stacks") {
        this.pendingBonusBlocks = this.calculatePendingBonusBlocks();
    }

    if (this.competition) {
        this.competition.updatePlayerResult(connector.platform.getUserID(), this.board.countDiscoveredWords());
    }

    if (this.updateCurrentHighlighter) {
        this.updateCurrentHighlighter();
    }

    this.score.addPoints(10 * letters.length);

    var tutorialStep = cleverapps.tutorial.getCurrentActiveStep();
    if (tutorialStep && tutorialStep.name === "discover") {
        tutorialStep.execute();
    }

    this.trigger("openWord");
};

WordGame.prototype.getDailyCupStars = function () {
    return this.extra.getAllFoundWords().length + Object.keys(this.board.foundWordsSet).length;
};

WordGame.prototype.showStartGameMessage = function (f, silent) {
    var message;
    if (cleverapps.isRumble() && cleverapps.knockoutGame.rumble.getCurrentRound().isLast()) {
        message = "message.lastRound";
    } else if (this.level.isBonus() && cleverapps.config.subtype === "stacks") {
        message = "message.bonusLevel";
    } else if (this.level.isHard()) {
        message = "message.hardLevel";
    } else if (this.level.isTricky()) {
        message = "message.trickyLevel";
    }

    if (message && !silent) {
        this.showMessage(message, f);
    } else {
        f();
    }
};

WordGame.STATUSES = {
    BOARD: 0,
    BOARD_FOUND: 1,
    EXTRA: 2,
    EXTRA_FOUND: 3,
    WRONG: 4,
    SHORT: 5
};

WordGame.BOARD_COIN = 1;

WordGame.prototype.stop = function () {
    cleverapps.competitionStatistics.onGameStop(this);
    this.hintBooster.clear();
    this.keypad.stop();

    if (this.competition) {
        this.competition.stop();
    }

    this.hintNotification.stop();
    this.current.clear();

    if (this.bonusTimer) {
        this.bonusTimer.stop();
    }

    GameBase.prototype.stop.call(this);
};

WordGame.prototype.getMinWordLength = function () {
    if (["stacks"].indexOf(cleverapps.config.subtype) !== -1) {
        if (cleverapps.settings.language === "ja" || cleverapps.settings.language === "ko") {
            return 2;
        }
        return 3;
    }
    var minLength = this.levelContent.words[0].length;

    for (var i = 0; i < this.levelContent.words.length; i++) {
        if (this.levelContent.words[i].length < minLength) {
            minLength = this.levelContent.words[i];
        }
    }
    return minLength;
};

WordGame.prototype.analyze = function (word) {
    if (word.length < this.getMinWordLength()) {
        return WordGame.STATUSES.SHORT;
    }

    if (this.board.contains(word)) {
        return this.board.analyze(word);
    }

    if (this.extra.contains(word) && !cleverapps.tutorial.isActive()) {
        return this.extra.analyze(word);
    }

    return WordGame.STATUSES.WRONG;
};

WordGame.prototype.discoverOpened = function () {
    var letters;

    if (cleverapps.config.subtype === "stacks") {
        letters = this.keypad.findOpenedWord();
    } else {
        letters = this.board.findNewlyOpenedWord();
    }

    if (letters) {
        this.onAttempt(letters);
    }
};

WordGame.prototype.checkComplete = function () {
    if (this.board.isComplete() && this.outcome === GameBase.OUTCOME_UNKNOWN) {
        this.win();
    }
};

WordGame.prototype.showInterstitial = function () {
    if (this.status === WordGame.STATUSES.WRONG) {
        cleverapps.focusManager.display({
            focus: "showInterstitialWrong",
            action: function (f) {
                cleverapps.rewardedAdsManager.showInterstitial(AdsLimits.TYPES.INTERSTITIAL_INACTIVE, f);
            }
        });
    }
};

WordGame.prototype.resetStatus = function () {
    this.status = undefined;
};

WordGame.prototype.getInfo = function () {
    return {
        words: this.board.getAllFoundWords(),
        hints: this.board.calcHints(),
        extra: this.extra.getAllFoundWords(),
        clover: this.rewards.clover,
        timeStart: this.timer.getStartTime()
    };
};

WordGame.prototype.getInitialInfo = function () {
    this.needSave = true;
    return {
        words: [],
        extra: [],
        hints: [],
        clover: 0,
        timeStart: Date.now(),
        empty: true
    };
};

WordGame.prototype.addClover = function () {
    GameBase.prototype.addClover.apply(this, arguments);
    this.storeSave();
};

if (cleverapps.config.debugMode) {
    WordGame.prototype.discoverDebug = function (player) {
        if (player.player) {
            var words = this.board.getUndiscoveredWords();
            if (words.length > 0) {
                this.keypad.trigger("attempt", words[0]);
            }
        } else {
            this.competition.updatePlayerResult(player.id, player.amount + 1);
        }
    };
}

WordGame.prototype.totalItemsToSolve = function () {
    return this.board.countTotalWords();
};

WordGame.prototype.showScreen = function (f, silent) {
    cleverapps.focusManager.compound(f, [
        function (f) {
            this.board.showUp(f, silent);
            this.keypad.showUp(function () {}, silent);
        }.bind(this)
    ]);
};

WordGame.prototype.checkOneWordLeft = function () {
    if (this.board.isComplete() || this.lastWordFound) {
        return;
    }

    var words = this.board.getUndiscoveredWords();
    if (words.length === 1) {
        this.lastWordFound = true;
        this.board.trigger("lastWord", this.board.listWordIndexes(words[0]));
    }
};

WordGame.prototype.needBonusReward = function () {
    return this.bonusTimer;
};

WordGame.prototype.calcCompletePercent = function (level) {
    return Math.round(100 * Math.pow(0.999163, level - 1) * 10) / 10;
};

WordGame.BONUS_LEVEL_DURATION = "2 minutes";