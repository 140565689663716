/**
 * Created by Ivan on 31.01.2025
 */

var BonusLevelComponent = cc.Node.extend({
    ctor: function () {
        this._super();

        var styles = cleverapps.styles.BonusLevelComponent;

        this.setAnchorPoint(0.5, 0.5);
        this.setContentSize2(styles);

        var chestPath = bundles.game.jsons.bonus_chest_horizontal_json;
        var chest = this.chest = new cleverapps.Spine(chestPath);

        this.addChild(chest);
        chest.setPositionRound(styles.position);
        chest.setAnimation(0, "animation", true);
        chest.setVisible(false);
    },

    showUp: function () {
        var actions = [
            new cc.DelayTime(0.2),
            AnimationsLibrary.showUp(this.chest, { flash: true })
        ];

        if (Game.currentGame.collectedCoins) {
            actions = actions.concat([
                new cc.CallFunc(function () {
                    this.chest.setAnimation(0, "open", false);
                }.bind(this)),
                new cc.DelayTime(1),
                AnimationsLibrary.coinCloud(this, {
                    reward: Game.currentGame.collectedCoins
                })
            ]);
        }

        this.chest.runAction(new cc.Sequence(actions));
    }
});

cleverapps.styles.BonusLevelComponent = {
    width: 600,
    height: 600,
    position: {
        x: { align: "center" },
        y: { align: "center" }
    }
};