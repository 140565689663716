/**
 * Created by olga on 26.12.2024
 */

var BoardTutorial = function () {
    return new TutorialScenario(TutorialScenario.BASIC_TUTORIAL_NAME, {
        listSteps: BoardTutorial.listSteps,

        onStart: function () {
            Game.currentGame.keypad.hideShuffleButton();
        },

        onFinish: function () {
            Game.currentGame.keypad.showShuffleButton();
        },

        isAvailable: function () {
            var game = Game.currentGame;

            if (!game) {
                return false;
            }
            
            return game.introPlaying ? game.levelWithTutorial() && BoardTutorialHelper.listTutorialWords(2).length
                : cleverapps.isKnockoutGame() && !cleverapps.forces.isShown(Forces.KEYPAD.id);
        }
    });
};

BoardTutorial.listSteps = function () {
    var steps = [];

    BoardTutorialHelper.listTutorialWords(3).forEach(function (word, index) {
        var options = {};
        options.word = word;
        options.pathColor = cleverapps.styles.COLORS.PATH_COLOR;

        if (index === 0 && !cleverapps.forces.isShown(Forces.KEYPAD.id)) {
            options.force = Forces.KEYPAD;
        } else {
            options.highlightBoard = true;
        }

        steps.push(BoardTutorialHelper.getDiscoverWordStep(options));
    });

    return steps;
};
